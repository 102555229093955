$lightOrange: #ffedc2;

body {
    background: #fff !important;
}
#dashboard {
    background: linear-gradient(249.93deg, #086788 11.29%, #0b303d 84.82%) no-repeat fixed !important;
}
main {
    padding-top: 72px;
    padding-bottom: 100px;
}

#guestLayout {
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(249.93deg, #086788 11.29%, #0b303d 84.82%) no-repeat fixed !important;
    #app {
        /*background-image: url('../img/bg_image_checkmark.svg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: 75% bottom;*/

        height: 100vh;

        main {
            height: 100vh;
        }
    }
}

.text-light-orange {
    color: $lightOrange;
}

#guestContainer {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

footer {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.navbar {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    .navbar-brand {
        width: 115px;
    }
}

.dashboard-buttons {
    img {
        width: 48px;
    }
}

#projectBoxes {
    #projectsPlanning, #receivedOffers {
        background: linear-gradient(180deg, rgba(7, 160, 195, 0.5) 0%, rgba(255, 255, 255, 0) 15%);
    }

    #projectsWaitingForOffers, #selectedOrders {
        background: linear-gradient(180deg, rgba(229, 39, 36, 0.3) 0%, rgba(255, 255, 255, 0) 15%);
    }

    #projectsInProduction, #answeredOffers {
        background: linear-gradient(180deg, rgba(249, 221, 77, 0.5) 0%, rgba(255, 255, 255, 0) 15%);
    }

    #projectsFinished, #declinedOrders {
        background: linear-gradient(180deg, rgba(8, 103, 136, 0.3) 0%, rgba(255, 255, 255, 0) 15%);
    }
}

.dashboard-box {
    height: 400px;
}

.blue-rounded-border {
    box-shadow: 0px 0px 20px rgba(8, 103, 136, 0.1);
    border-radius: 24px;
}

#projectFormContainer {
    & > #projectForm > .row {
        height: calc(100vh - 135px);
    }

    #projectTabContentContainer {
        overflow-y: auto;
        max-height: 100%;
        padding-bottom: 2rem;
    }

    #projectRightMenuContainer {
        overflow-y: auto;
        max-height: 100%;
        padding-bottom: 2rem;
    }
}

#projectMenuContainer {
    background: linear-gradient(199.45deg, #086788 50.55%, #07a0c3 91.83%);

    .list-group {
        .list-group-item {
            color: #fff;
            text-transform: uppercase;
            border: 0;
            border-radius: 0;
            background: transparent;
            padding-left: 8.33333%;

            &.active {
                background: #07a0c3;
            }
        }
    }
}

#projectRightMenuContainer {
    background: rgba(8, 103, 136, 0.05);
    border-left: 1px solid rgba(8, 103, 136, 0.2);

    .text-secondary {
        font-size: 0.625rem !important;
    }

    .price-text {
        font-size: 1rem !important;
    }

    #rightMenuTabs {
        border: 0;

        .nav-link {
            border: 0;
            border-radius: 0;
            background: rgba(8, 103, 136, 0.05);
            color: #086788;

            &.active {
                color: #000;
                background: transparent;
            }
        }
    }
}

.table-primary {
    background: transparent;

    thead {
        background: transparent;
        border-bottom: 1px solid rgba(8, 103, 136, 0.2);

        th {
            font-weight: normal;
            font-size: 12px;
            color: #07a0c3;
        }
    }

    tbody {
        background: transparent;
        tr {
            background: transparent;
            border-bottom: 1px solid rgba(8, 103, 136, 0.2);
        }
    }
}

#pictureForm {
    img {
        height: 128px;
        width: auto;
    }
}

.toast {
    background-color: #030303;
}

.toast-success {
    background-color: #51a351;
}

.toast-error {
    background-color: #bd362f;
}

.toast-info {
    background-color: #2f96b4;
}

.toast-warning {
    background-color: #f89406;
}

.offer-menu {
    min-height: 100px;
    width: 80%;
    margin-top: 100px;
    margin-left: 50px;

    .offer-list {
        list-style: none !important;
        border: 1px solid #000;
        border-bottom: none;
        a {
            display: block;
            line-height: 40px;
            padding-left: 20px;
            color: #000;
            text-decoration: none;
            font-weight: bold;
        }
    }
    .offer-list:last-child {
        border-bottom: 1px solid #000;
    }
}
.top-menu-container {
    border-bottom: 1px solid #07a0c3;
}
.offer-top-menu-items {
    line-height: 40px !important;
    padding: 11px 20px;
    font-weight: bold;
    color: #000;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.offer-active {
    background: #07a0c3;
    color: #fff;
}
.offer-content {
    margin-top: 45px;
    margin-bottom: 200px;
}
#offersContent {
    max-height: 400px;
    width: auto;
    overflow-x: hidden;
    overflow-y: scroll;

    table {
        margin-left: 5px !important;
    }
}
.offer-third-parties {
    padding-left: 25px !important;
}
.checkbox-party {
    padding-left: 50px !important;
}
.inner-radio-div {
    label {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .form-check-input {
        margin-left: 0px !important;
    }
}
.pagination-holder {
    margin-top: 50px;
}
#offerDetailsModelBody {
    table tr td {
        font-size: 13px !important;
        margin-bottom: 6px !important;
    }
}
#costCalculation {
    margin-bottom: 100px;
}

.admin-menu-container {
    h5 {
        max-width: 75%;
    }

    i {
        opacity: 0.3;
    }

    .col {
        height: 100px;
    }

    .border {
        border-radius: 25px;
    }
}
#eventTypes {
    margin-top: 50px;
    h2 {
        padding-left: 0px;
    }
}
.event_create {
    margin-bottom: 20px;
    padding-left: 0px !important;
}
.form-select:disabled {
    background-color: #fff;
}

.form-select {
    background-color: rgba(8, 103, 136, 0.05);
}

.form-control:disabled,
.form-control[readonly] {
    box-shadow: none;
}
.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    border-radius: .25rem;
}
.toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #6c757d;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word;
}


.pagination-placement {
    position: absolute;
    bottom: 0;
    left: 25%;
    right: 25%;
}


.third-party-choose-component{
    margin-top: 50px;;
}
.divider{
    border-left: 1px solid #ccc;
}

.circle {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
    float: right;
    margin-top: 6px;
    margin-left: 6px;
  }
.create-disabled{
    pointer-events: none;
    cursor: default;
}

.uppy-Dashboard-inner {
    z-index: 1052 !important;
}

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
    z-index: 1051 !important;
}

.mood-image{
    height: 200px;
}

.delete-mood-image {
    display: block;
}

.coming-in-spring-overlay {
    width: calc(100% - 32px);
    height: 100%;
    background-image: linear-gradient(45deg, #c9c9c95A 25%, #a3a3a35A 25%, #a3a3a35A 50%, #c9c9c95A 50%, #c9c9c95A 75%, #a3a3a35A 75%, #a3a3a35A 100%);
    background-size: 56.57px 56.57px;
    position: absolute;
    display: flex;
    align-items: center;
    border-radius: 24px;

    h1 {
        rotate: -45deg;
        color: #086788;
    }
}

#costEstimateCalculatorTabContent {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}
